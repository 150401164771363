<template>
  <div class="container margin">
    <side-nav>
      <div class="report">
        <div class="report__info flex flex__align-center">
          <div class="report__info-user flex flex__align-center">
            <img :src="getFileAccessHttpUrl(avatar)" alt class="report__info-img" />
            <div class="report__info-name">{{userName}}</div>
          </div>
          <div class="report__info-line"></div>
          <div class="report__info-area">
            <div class="report__info-department">部门：{{depart}}</div>
            <div class="report__info-date">到期时间：{{learnData.endTime ? learnData.endTime : '-'}}</div>
          </div>
          <div class="report__info-right">
            <div class="report__info-task">任务：{{learnData.taskName}}</div>
            <div class="report__info-state" v-if="learnData.expireDay">
              状态：
              <span class="report__info-state_red">
                <template v-if="learnData.taskExpireStatus==='NOT_START'">未开始</template>
                <template v-else-if="learnData.taskExpireStatus==='NOT_EXPIRE'">未过期</template>
                <template v-else-if="learnData.taskExpireStatus==='WILL_EXPIRE'">临期</template>
                <template v-else>已过期</template>
              </span>
            </div>
          </div>
        </div>
        <!-- "TaskExpireStatusEnum": {
        "NOT_START": "未开始",
        "NOT_EXPIRE": "未过期",
        "WILL_EXPIRE": "临期",
        "EXPIRED": "已过期"
        },-->
        <div class="flex report__content">
          <div class="report__chart" id="main"></div>
          <div class="report__right">
            <!-- <task :list="learnData.learningTaskLevelProgressList" /> -->
            <task
              :list="learnData.learningTaskLevelProgressList"
              :studyType="learnData.studyType"
              :lockFlag="learnData.lockFlag"
              :status="learnData.taskExpireStatus"
            />
          </div>
        </div>
      </div>
    </side-nav>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
// 引入饼状图图表，图表后缀都为 Chart
import { PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import sideNav from "@/components/side-nav";
import task from "@/components/task-list";
import { getTaskData } from "@/api";
import Vue from "vue";
import { USER_NAME, USER_AVATAR } from "@/store/mutation-types.js";
import { getFileAccessHttpUrl } from "@/utils/tools";
import { DEPARTMENT } from "@/store/mutation-types.js";
export default {
  components: { sideNav, task },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
          // formatter: "{b} : {c} ({d}%)"
          formatter: function(data) {
            return (
              data.name +
              ": " +
              data.value +
              " (" +
              data.percent.toFixed(0) +
              "%)"
            );
          }
        },
        legend: {
          bottom: 10
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            selectedMode: "single",
            label: {
              formatter: "{b}:{d}%"
            },
            // formatter: "{b} : {c} ({d}%)",
            data: [
              { value: 0, name: "已完成" },
              { value: 0, name: "未完成" }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      },
      myChart: null,
      learnData: {},
      taskId: "",
      taskPathId: "",
      userName: "",
      avatar: "",
      depart: Vue.ls.get(DEPARTMENT)
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return url => {
        return getFileAccessHttpUrl(url);
      };
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.taskId = Vue.ls.get("TASK_ID");
      this.taskPathId = Vue.ls.get("TASK_PATH_ID");
      this.userName = Vue.ls.get(USER_NAME);
      this.avatar = Vue.ls.get(USER_AVATAR);
    } else {
      console.log("传进来有id");
    }
    if (this.taskId != null && this.taskPathId != null) {
      this.getData();
    }
  },
  mounted() {
    // 注册必须的组件
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      LegendComponent,
      PieChart,
      CanvasRenderer
    ]);
    this.myChart = echarts.init(document.getElementById("main"));
    this.myChart.setOption(this.option);
  },
  methods: {
    // 获取学习任务
    getData() {
      getTaskData({
        taskId: this.taskId,
        taskPathId: this.taskPathId
      }).then(res => {
        this.learnData = res.result;
        console.log(this.learnData, "learnData");
        console.log(this.learnData, "this.learnData");
        this.option.series[0].data[0].value = this.learnData.finishedLevelCount;
        this.option.series[0].data[1].value =
          this.learnData.levelCount - this.learnData.finishedLevelCount;
        this.myChart.setOption(this.option);
      });
    }
  }
};
</script>

<style lang = "scss" scoped>
.report {
  &__info {
    height: 146px;
    background: linear-gradient(124deg, #ff9d73, #fe7090);
    border-radius: 8px;
    color: #ffffff;
    padding-left: 56px;
    font-size: 16px;
    &-user {
      width: 236px;
    }
    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    &-name {
      font-size: 30px;
      font-weight: bold;
      margin-left: 16px;
    }
    &-line {
      width: 1px;
      height: 68px;
      background: #fff;
      margin-right: 53px;
    }
    &-area {
      font-weight: 400;
      margin-right: 95px;
    }
    &-date {
      margin-top: 15px;
    }
    &-state {
      margin-top: 15px;
      &_red {
        color: #ff0000;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  &__content {
    margin-top: 80px;
  }
  &__chart {
    /* width: 437px; */
    width: 564px;
    height: 413px;
  }
  &__right {
    padding-left: 80px;
    border-left: 1px solid #eeeeee;
  }
}
</style>
